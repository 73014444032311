@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/positioning";
@use "src/sedestral-interface-modules/sedestral-interface-sass/mixins/texts";

.staticPageLoaders {
  left: 61px;
  width: calc(100% - 61px);
  position: fixed;
  height: 100%;
  z-index: 99999999;
  background: var(--white);


  .content {
    height: 100%;
    width: 100%;

    @include positioning.flex-globally;

    .relative {
      width: fit-content;
      position: relative;
      pointer-events: none;
      user-select: none;
      height: 175px;
      margin-left: -25px;

      .capsule {
        height: 80px;
        width: 80px;
        position: absolute;
        left: 100%;
        margin-top: -11px;
      }

      .name {
        float: left;
        font-size: 56px;
        line-height: 35px;
        opacity: 0;

        @include texts.font("bold");

        .company {
          margin-left: 2px;

          span {
            font-size: 15px;

            @include texts.font("semi-bold");
          }

          font-size: 19px;

          @include texts.font("extra-bold");
        }
      }
    }
  }

  @media screen and (max-width: 480px) {
    left: 0 !important;
    width: 100% !important;


    .relative {
      display: flex !important;
      justify-content: center;
      align-items: center;
      flex-direction: column;
    }

    .name {
      font-size: 40px !important;
    }

    .capsule {
      width: 50px !important;
      height: 50px !important;
      margin-top: -150px !important;
    }
  }
}