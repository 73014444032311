import 'regenerator-runtime/runtime';
import {SedestralLogic} from "./sedestral-interface-modules/sedestral-interface-component/SedestralLogic";
import {Network} from "./network/Network";
import {Panel} from "./products/panel/Panel";
import {PanelNetwork} from "./products/panel/network/PanelNetwork";
import {config} from "./config";
import {ProductName} from "./models/product/ProductName";
import {ProductType} from "./models/product/ProductType";
import Hotjar from '@hotjar/browser';

config.import(ProductName.toString(ProductType.PANEL));
SedestralLogic.ready(async () => {
    await SedestralLogic.init();
    await PanelNetwork.init();
    await Network.init(ProductName.toString(ProductType.PANEL));
    await Panel.init();


    try {
        if (!config.isDev) {
            const siteId = config.hotjarPanelId;
            const hotjarVersion = 6;
            Hotjar.init(siteId, hotjarVersion);
        }
    } catch (e) {
        console.error(e);
    }
});