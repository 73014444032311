import {
    Component
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component/interface/component/Component";
import {
    MenuComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/menu/MenuComponent";
import {
    VisualHoverTooltipWhiteComponent
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-visual/tooltip/VisualHoverTooltipWhiteComponent";
import {
    MenuIcon
} from "../../../../../../sedestral-interface-modules/sedestral-interface-component-global/menu/types/MenuIcon";
import {Resources} from "../../../../../../resources/Resources";

export class SiteShortcutComponent extends VisualHoverTooltipWhiteComponent {
    constructor(component: Component) {
        super(component, 10, 240);
        this.template = this.draw(new MenuComponent([
            {icon: MenuIcon.MEMBERS, text: Resources.t("words.manageCollaborators"), link: "/general/members"},
            {icon: MenuIcon.ROLES, text: Resources.t("words.permissionsName"), link: "/general/roles"},
            {icon: MenuIcon.BLOG, text: Resources.t("words.blogArticle"), link: "/smartblog"}
        ]));
    }

    commit() {
        this.setStyle("padding:10px;");
        super.commit();
    }
}