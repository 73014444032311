import {Network} from "../../../network/Network";
import {SedestralRouter} from "../../../sedestral-interface-modules/sedestral-interface-router/SedestralRouter";
import {LoaderStatic} from "./static/loaders/LoaderStatic";
import {SlidingLoaderStatic} from "./static/loaders/sliding/SlidingLoaderStatic";
import {NotificationsStatic} from "./static/notifications/NotificationsStatic";
import {NavigationStatic} from "./static/navigation/NavigationStatic";
import {PageLoaderStatic} from "./static/loaders/page/PageLoaderStatic";
import {ProductType} from "../../../models/product/ProductType";
import {ComposerStatic} from "./static/composer/ComposerStatic";
import {TwoFactorStatic} from "./static/twofactor/TwoFactorStatic";
import {
    ISedestralRouterConfig
} from "../../../sedestral-interface-modules/sedestral-interface-router/types/ISedestralRouterConfig";
import {isMobile} from "../../../sedestral-interface-modules/sedestral-interface-component/utilities/IsMobile";
import {MobileNavigationStatic} from "../../mobile/components/static/navigation/MobileNavigationStatic";
import {
    SedestralInterface
} from "../../../sedestral-interface-modules/sedestral-interface-component/interface/SedestralInterface";
import {
    SedestralStorage
} from "../../../sedestral-interface-modules/sedestral-interface-component/memory/SedestralStorage";

export class PanelRouter extends SedestralRouter {

    async init(config?: ISedestralRouterConfig): Promise<void> {
        super.init(config);
        super.initStarted();

        if (Network.logged) {
            this.static.components.navigation.init();
        }

        if (isMobile())
            SedestralInterface.body.addClass("mobile");
    }

    registerPages() {
        this.pages.registerPage("/general", async () => {
            return new (await this.importPage(() => import("./pages/general/GeneralPage"), ProductType.GENERAL)).GeneralPage();
        });
        this.pages.registerPage("/inbox", async () => {
            return new (await this.importPage(() => import("./pages/inbox/InboxPage"), ProductType.INBOX)).InboxPage();
        });
        this.pages.registerPage("/livechats", async () => {
            return new (await this.importPage(() => import("./pages/livechat/LiveChatPage"), ProductType.LIVECHAT)).LiveChatPage();
        });
        this.pages.registerPage("/mails", async () => {
            return new (await this.importPage(() => import("./pages/mails/MailsPage"), ProductType.MAILS)).MailsPage();
        });
        this.pages.registerPage("/website", async () => {
            return new (await this.importPage(() => import("../../website/components/pages/WebsiteHomePage"), ProductType.WEBSITE)).WebsitePage();
        });
        this.pages.registerPage("/smartdoc", async () => {
            return new (await this.importPage(() => import("./pages/helpcenter/HelpCenterPage"), ProductType.HELPCENTER)).HelpCenterPage();
        });
        this.pages.registerPage("/smartblog", async () => {
            return new (await this.importPage(() => import("./pages/blog/BlogPage"), ProductType.BLOG)).BlogPage();
        });
        this.pages.registerPage("/chatbot", async () => {
            return new (await this.importPage(() => import("./pages/chatbot/ChatBotPage"), ProductType.CHAT_BOT)).ChatBotPage();
        });
        this.pages.registerPage("/publisher", async () => {
            return new (await this.importPage(() => import("./pages/publisher/PublisherPage"), ProductType.PUBLISHER)).PublisherPage();
        });
        this.pages.registerPage("/engagements", async () => {
            return new (await this.importPage(() => import("./pages/engagement/EngagementPage"), ProductType.ENGAGEMENT)).EngagementPage();
        });
        this.pages.registerPage("/advertising", async () => {
            return new (await this.importPage(() => import("./pages/advertising/AdvertisingPage"), ProductType.ADVERTISING)).AdvertisingPage();
        });
        this.pages.registerPage("/mailling", async () => {
            return new (await this.importPage(() => import("./pages/mailling/MaillingPage"), ProductType.MAILLING)).MaillingPage();
        });
        this.pages.registerPage("/medias", async () => {
            return new (await this.importPage(() => import("./pages/medias/MediasPage"), ProductType.MEDIAS)).MediasPage();
        });
        this.pages.registerPage("/sell", async () => {
            return new (await this.importPage(() => import("./pages/sell/SellPage"), ProductType.SELL)).SellPage();
        });
        this.pages.registerPage("/documents", async () => {
            return new (await this.importPage(() => import("./pages/documents/DocumentsPage"), ProductType.DOCUMENTS)).DocumentsPage();
        });
        this.pages.registerPage("/products", async () => {
            return new (await this.importPage(() => import("./pages/products/ProductsPage"), ProductType.PRODUCTS)).ProductsPage();
        });
        this.pages.registerPage("/estimate", async () => {
            return new (await this.importPage(() => import("./pages/estimate/EstimatePage"), ProductType.ESTIMATE)).EstimatePage();
        });

        this.pages.registerPage("/statistics", async () => {
            return new (await this.importPage(() => import("./pages/statistics/StatisticsPage"), ProductType.STATISTICS)).StatisticsPage();
        });

        this.pages.registerPage("/contacts", async () => {
            return new (await this.importPage(() => import("./pages/contacts/ContactsPage"), ProductType.CONTACTS)).ContactsPage();
        });
        this.pages.registerPage("/calendar", async () => {
            return new (await this.importPage(() => import("./pages/calendar/CalendarPage"), ProductType.CALENDAR)).CalendarPage();
        });


        this.pages.registerPage("/404", async () => {
            return new (await this.importPage(() => import("./pages/global/notfound/NotFoundPage"))).NotFoundPage();
        });
        this.pages.registerPage("/login", async () => {
            return new (await this.importPage(() => import("./pages/login/LoginPage"))).LoginPage();
        });
        this.pages.registerPage("/signup", async () => {
            return new (await this.importPage(() => import("./pages/signup/SignupPage"))).SignupPage();
        });
        this.pages.registerPage("/invitation", async () => {
            return new (await this.importPage(() => import("./pages/invitation/InvitationPage"))).InvitationPage();
        });
        this.pages.registerPage("/password", async () => {
            return new (await this.importPage(() => import("./pages/password/lost/PasswordLostPage"))).PasswordLostPage();
        });
        this.pages.registerPage("/sites", async () => {
            return new (await this.importPage(() => import("./pages/sites/SitesPage"))).SitesPage();
        });
        this.pages.registerPage("/test2", async () => {
            return new (await this.importPage(() => import("./pages/test/Test2Page"))).Test2Page();
        });
        this.pages.registerPage("/admin", async () => {
            return new (await this.importPage(() => import("./pages/admin/AdminPage"))).AdminPage();
        });
        this.pages.registerPage("/admin-tracking", async () => {
            return new (await this.importPage(() => import("./pages/admin/AdminTrackingPage"))).AdminTrackingPage();
        });

        this.pages.path404 = "/404";
        this.pages.pathHome = "/smartblog";
        this.pages.pathLogin = "/login";
    }

    registerStatic() {
        this.static.register("loader", new LoaderStatic());
        this.static.register("pageLoader", new PageLoaderStatic());
        this.static.register("slidingLoader", new SlidingLoaderStatic());
        this.static.register("notifications", new NotificationsStatic());
        this.static.register("navigation", isMobile() ? new MobileNavigationStatic() : new NavigationStatic());
        this.static.register("composer", new ComposerStatic());
        this.static.register("twoFactor", new TwoFactorStatic());
    }

    onLogin() {
        this.pages.redirect(this.pages.pathHome);
        this.static.components.navigation.init();
    }

    onLogout() {
        SedestralStorage.setItem("lastRoute", location.pathname);
        setTimeout(async () => {
            await this.pages.redirect(this.pages.pathLogin);
            this.static.components.navigation.displayHide();
        }, 1500);
    }
}